import LoadingIcon from "../SVGs/LoadingIcon";

export default function Button({
  disabled,
  onClick,
  label,
  loading,
  className,
  icon,
  type = "primary",
}: {
  label: string;
  disabled?: boolean;
  onClick: (e: any) => void;
  loading?: boolean;
  className?: string;
  icon?: React.ReactNode;
  type?: "primary" | "outlined";
}) {
  return (
    <button
      className={`px-4 py-3 rounded-md flex items-center justify-center w-full
      ${type === "primary" ? `bg-violet-500 text-white ${disabled ? "opacity-40" : "hover:bg-violet-600"}` : ""}
      ${
        type === "outlined"
          ? `bg-white text-violet-500 outline-1 outline-violet-500 outline ${disabled ? "opacity-40" : "hover:bg-slate-200"}`
          : ""
      }  
      ${disabled ? "!cursor-not-allowed" : "cursor-pointer"}
      ${className ? className : ""}
      `}
      disabled={disabled}
      onClick={onClick}
    >
      {loading && <LoadingIcon />}
      <div className="flex justify-center items-center">
        {icon && !loading && <div className="mr-1">{icon}</div>} {label}
      </div>
    </button>
  );
}
