import { Contract } from "ethers";
import { useEffect, useState } from "react";
import { SendRequest } from "../../models/SendRequest";
import LoadingIcon from "../SVGs/LoadingIcon";
import OutgoingSendRequestsTable from "./OutgoingSendRequestsTable";
import { usePoller } from "eth-hooks";

export default function OutgoingSendRequests({ contract }: { contract: Contract }) {
  const [outgoingRequests, setOutgoingRequests] = useState<SendRequest[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOutgoingSendRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePoller(async () => {
    await getOutgoingSendRequests();
  }, 1000);

  async function getOutgoingSendRequests() {
    try {
      const receivedRequests = await contract.getSendRequestsSentByMe();

      setOutgoingRequests(receivedRequests);
      if (loading) setLoading(false);

      setError(undefined);
    } catch (e: any) {
      setError(e.toString());
    }
  }

  return (
    <>
      <div className="my-16">
        {loading ? (
          <div className="w-full h-full flex justify-center">
            <LoadingIcon />
          </div>
        ) : (
          <>
            {outgoingRequests.length === 0 ? (
              <div className="w-full h-full flex justify-center">No Incoming Requests</div>
            ) : (
              <>
                {error && <p className="text-red-600">{error}</p>}
                <OutgoingSendRequestsTable contract={contract} outgoingRequests={outgoingRequests}></OutgoingSendRequestsTable>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
