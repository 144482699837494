import { useState } from "react";
import CopyButton from "../../Dumb/CopyButton";
import Dropdown from "../../Dumb/Dropdown";
import Input from "../../Dumb/Input";

enum SafeSendMeQueryParams {
  AMOUNT = "amount",
  CURRENCY = "currency",
}

const safeSendMeValidCurrencies: string[] = ["ETH", "USD"];
const safeSendMeBaseURL = "https://safesend.me";

export default function CustomSafeSendMe({ address }: { address?: string }) {
  const [currency, setCurrency] = useState("");
  const [amount, setAmount] = useState();

  const copySafeSendMeLink = () => {
    const queryParams = new URLSearchParams();

    if (amount) queryParams.append(SafeSendMeQueryParams.AMOUNT, amount);
    if (currency) queryParams.append(SafeSendMeQueryParams.CURRENCY, currency);

    let safeSendMeLink = `${safeSendMeBaseURL}/${address}`;
    if (queryParams.toString()) safeSendMeLink += `?${queryParams}`;

    const copyToClipboard = async () => {
      await navigator.clipboard.writeText(safeSendMeLink);
    };
    copyToClipboard();
  };

  return (
    <div className="w-full h-full py-6 px-5 flex flex-col items-start justify-center shadow-sm">
      <Dropdown label="Currency" placeholder="Any" options={safeSendMeValidCurrencies} selectedChanged={setCurrency} />
      <Input inlineLabel={currency ? currency : ""} label="Amount" placeholder={"Any"} value={amount} onChange={setAmount} />
      <div className="mt-3 w-full">
        <CopyButton label="SafeSend.me" onClick={copySafeSendMeLink} />
      </div>
    </div>
  );
}
