import { useCallback, useEffect, useMemo, useState } from "react";
import { Subject } from "rxjs";
import Input from "../../Dumb/Input";
import { usePoller } from "eth-hooks";

type ValidCurrency = "USD" | "ETH";
const validCurrencies = ["USD", "ETH"];

export default function Amount({
  amountInEthChanged,
  clearAmount$,
  startWith,
}: {
  amountInEthChanged: (amount: string) => void;
  clearAmount$?: Subject<void>;
  startWith?: { amount?: string; currency?: string };
}) {
  const [ethPriceInUSD, setEthPriceInUSD] = useState<undefined | number>();
  const [amountInEther, setAmountInEther] = useState("");
  const [amountInUSD, setAmountInUSD] = useState("");
  const [startWithSetOnce, setStartWithSetOnce] = useState(false);

  const fetchETHPriceInUSD = async () => {
    fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then(response => response.json())
      .then(result => setEthPriceInUSD(result["USD"] ?? undefined));
  };

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchETHPriceInUSD();
  }, []);

  usePoller(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchETHPriceInUSD();
  }, 30000);

  const setAndUpdateAmountInEth = useCallback(
    (amount: string) => {
      setAmountInEther(amount);
      amountInEthChanged(amount);
    },
    [amountInEthChanged],
  );

  const setUSDAmountAndUpdateEth = useCallback(
    (amount: string) => {
      setAmountInUSD(amount);
      if (!ethPriceInUSD || !amount || isNaN(Number(amount))) {
        setAndUpdateAmountInEth("");
        return;
      }

      const updateETH = async () => {
        let convertedToETH = Number(amount) / ethPriceInUSD + "";
        if (convertedToETH.length > 13) convertedToETH = convertedToETH.substring(0, 13);

        setAndUpdateAmountInEth(convertedToETH);
      };
      updateETH();
    },
    [ethPriceInUSD, setAndUpdateAmountInEth],
  );

  const setEthAmountAndUpdateUSD = useCallback(
    (amount: string) => {
      setAndUpdateAmountInEth(amount);
      if (!ethPriceInUSD || !amount || isNaN(Number(amount))) {
        setAmountInUSD("");
        return;
      }
      const updateUSD = async () => {
        let convertedToUSD = Number(amount) * ethPriceInUSD + "";
        if (convertedToUSD.length > 13) convertedToUSD = convertedToUSD.substring(0, 13);
        setAmountInUSD(convertedToUSD);
      };
      updateUSD();
    },
    [ethPriceInUSD, setAndUpdateAmountInEth],
  );

  useEffect(() => {
    if (!startWithSetOnce && !!startWith?.amount && ethPriceInUSD) {
      const currencyIsSetAndValid = startWith.currency && validCurrencies.includes(startWith.currency);

      if (currencyIsSetAndValid) {
        const currency: ValidCurrency = startWith.currency?.toUpperCase() as ValidCurrency;

        switch (currency) {
          case "USD":
            setUSDAmountAndUpdateEth(startWith.amount);
            break;
          case "ETH":
          default:
            setEthAmountAndUpdateUSD(startWith.amount);
            break;
        }
      } else {
        setEthAmountAndUpdateUSD(startWith.amount);
      }

      setStartWithSetOnce(true);
    }
  }, [setEthAmountAndUpdateUSD, setUSDAmountAndUpdateEth, startWith, ethPriceInUSD, startWithSetOnce]);

  const isAmountValid = useMemo(() => !isNaN(+amountInEther) && +amountInEther < 1, [amountInEther]);

  useEffect(() => {
    if (!clearAmount$) return;

    clearAmount$.subscribe(() => {
      setEthAmountAndUpdateUSD("");
    });
  }, [clearAmount$, setEthAmountAndUpdateUSD]);

  const getUSDAmountPlaceholder: string = useMemo(() => {
    if (!ethPriceInUSD) return "";

    return `${Math.round(ethPriceInUSD * 100)}`;
  }, [ethPriceInUSD]);

  return (
    <div className="flex w-full">
      <div className="mr-3 w-full">
        <Input
          containerClassname=""
          label="Amount"
          inlineLabel="ETH"
          value={amountInEther}
          placeholder="100"
          onChange={setEthAmountAndUpdateUSD}
          error={{
            hasError: !!amountInEther && !isAmountValid,
            message: "",
          }}
          success={{ isSuccessful: !!amountInEther && isAmountValid }}
        />
      </div>
      <div className={`w-full`}>
        <Input
          label=""
          inlineLabel="USD"
          value={amountInUSD}
          placeholder={getUSDAmountPlaceholder}
          onChange={setUSDAmountAndUpdateEth}
          error={{
            hasError: !!amountInEther && !isAmountValid,
            message: +amountInEther >= 1 ? "Only send <1 ETH in Alpha" : "Invalid amount",
          }}
          success={{ isSuccessful: !!amountInEther && isAmountValid }}
        />
      </div>
    </div>
  );
}
