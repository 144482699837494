import { ethers, Signer } from "ethers";
import CreateSendRequest from "../components/SafeSend/SendRequest/CreateSendRequest";
import { GOERLI_PROXY_CONTRACT_ADDRESS } from "../constants";
import { contractAbi } from "./contract-abi";

/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 **/

function Home({ userSigner }: { userSigner: Signer }) {
  const contract = new ethers.Contract(GOERLI_PROXY_CONTRACT_ADDRESS, contractAbi, userSigner);

  return (
    <div className="mt-8 pb-16">
      <div className="w-full flex justify-center">
        <CreateSendRequest provider={userSigner?.provider} contract={contract} userSigner={userSigner} />
      </div>
    </div>
  );
}

export default Home;
