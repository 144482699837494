import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import { notification } from "antd";
import { Contract, ethers } from "ethers";
import { useState } from "react";
import { SendRequest } from "../../models/SendRequest";
import LoadingIcon from "../SVGs/LoadingIcon";

enum RequestStatus {
  RETRACTED = "retracted",
  RETRACTING = "retracting",
  WAITING = "waiting",
}

interface RequestsStatus {
  [key: string]: RequestStatus;
}

export default function OutgoingSendRequestsTable({
  outgoingRequests,
  contract,
}: {
  outgoingRequests: SendRequest[];
  contract: Contract;
}) {
  const [requestsStatus, setRequestsStatus] = useState<RequestsStatus>({});

  async function retract(requestId: string) {
    const request = outgoingRequests.find(request => request.requestId === requestId);
    if (!request) {
      notification.error({
        message: "Retract Error",
        description: "Unknown problem, Please reload",
      });
      return;
    }

    await retractSendRequest(request.to, requestId);
  }

  async function retractSendRequest(to: string, requestId: string) {
    setRequestsStatus({ ...requestsStatus, [requestId]: RequestStatus.RETRACTING });

    try {
      const tx = await contract.retractSendRequest(to, requestId);

      notification.success({
        message: "Retracting",
        description: "Transaction is now processing",
      });
      await tx.wait();
      setRequestsStatus({ ...requestsStatus, [requestId]: RequestStatus.RETRACTED });

      notification.success({
        message: "Retracted",
        description: "Successfully retracted Send Request",
      });
    } catch (e: any) {
      notification.error({
        message: "Retract Error",
        description: e.toString(),
      });
      setRequestsStatus({ ...requestsStatus, [requestId]: RequestStatus.WAITING });
    }
  }

  return (
    <>
      <div className="text-xl font-medium">Outgoing Requests</div>
      <div className="mt-8 px-16 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block max-w-4xl py-2 md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Recipient
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Amount
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Password
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {outgoingRequests.map(request => (
                    <tr key={request.requestId.toString()}>
                      <td className="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-6">
                        {request.to.toString()}
                      </td>
                      <td className="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                        {ethers.utils.formatEther(request.amount).toString()} ETH
                      </td>
                      <td className="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                        {!!request.password.passwordIsSet ? (
                          <LockClosedIcon className="w-6 h-6" />
                        ) : (
                          <LockOpenIcon className="w-6 h-6" />
                        )}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6 flex justify-center">
                        <button
                          className={`text-indigo-600  p-2 rounded-xl ${
                            requestsStatus[request.requestId] === RequestStatus.RETRACTING
                              ? "!cursor-not-allowed !bg-indigo-400 hover:bg-indigo-400"
                              : "!cursor-pointer hover:bg-indigo-100"
                          }`}
                          disabled={requestsStatus[request.requestId] === RequestStatus.RETRACTING}
                          onClick={() => retract(request.requestId)}
                        >
                          {!requestsStatus[request.requestId] && "Retract"}
                          {requestsStatus[request.requestId] === RequestStatus.WAITING && "Retract"}
                          {requestsStatus[request.requestId] === RequestStatus.RETRACTING && (
                            <div className="flex text-white px-1">
                              <LoadingIcon />
                              Processing
                            </div>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
