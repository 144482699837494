import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";
import { notification } from "antd";
import { Contract, ethers } from "ethers";
import { useState } from "react";
import { SendRequest } from "../../models/SendRequest";
import LoadingIcon from "../SVGs/LoadingIcon";

enum RequestStatus {
  ACCEPTED = "accepted",
  ACCEPTING = "accepting",
  WAITING = "waiting",
  ENTERING_PASSWORD = "entering-password",
}

interface RequestsStatus {
  [key: string]: RequestStatus;
}

export default function IncomingSendRequestsTable({
  incomingRequests,
  contract,
}: {
  incomingRequests: SendRequest[];
  contract: Contract;
}) {
  const [requestsStatus, setRequestsStatus] = useState<RequestsStatus>({});
  const [passwordEntered, setPasswordEntered] = useState("");

  function accept(requestId: string) {
    const request = incomingRequests.find(request => request.requestId === requestId);
    if (!request) {
      notification.error({
        message: "Accept Error",
        description: "Unknown problem, Please reload",
      });
      return;
    }

    // TODO throw error
    if (!request.password.passwordIsSet) return;

    acceptSendRequest(request);
  }

  async function acceptSendRequest(request: SendRequest) {
    setRequestsStatus({ ...requestsStatus, [request.requestId]: RequestStatus.ACCEPTING });

    try {
      const hashedPassword = ethers.utils.keccak256(
        ethers.utils.toUtf8Bytes(passwordEntered + "" + request.password.salt + "" + request.from),
      );
      const tx = await contract.acceptSendRequest(request.requestId, hashedPassword);

      notification.success({
        message: "Accepting",
        description: "Transaction is now processing",
      });
      await tx.wait();
      setRequestsStatus({ ...requestsStatus, [request.requestId]: RequestStatus.ACCEPTED });
      notification.success({
        message: "Accepted",
        description: "Successfully accepted Send Request",
      });
    } catch (e: any) {
      notification.error({
        message: "Accept Error",
        description: e.toString(),
      });
      setRequestsStatus({ ...requestsStatus, [request.requestId]: RequestStatus.WAITING });
    }
  }

  return (
    <>
      <div className="text-xl font-medium">Incoming Requests</div>
      <div className="mt-8 px-16 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block max-w-5/6 w-fit py-2 md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Sender
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Amount
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Password
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {incomingRequests.map(request => (
                    <tr key={request.requestId.toString()}>
                      <td className="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-normal text-gray-900 sm:pl-6">
                        {request.from.toString()}
                      </td>
                      <td className="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                        {ethers.utils.formatEther(request.amount).toString()} ETH
                      </td>
                      <td className="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                        {!!request.password.passwordIsSet ? (
                          <div className="overflow-hidden  h-full">
                            {requestsStatus[request.requestId] === RequestStatus.ENTERING_PASSWORD ? (
                              <input
                                className="max-w-fit bg-gray-100 p-2 rounded-lg focus-visible:outline-none"
                                type="password"
                                placeholder="Enter Password"
                                value={passwordEntered}
                                onChange={e => setPasswordEntered(e.target.value)}
                              />
                            ) : (
                              <LockClosedIcon className="w-6 h-6" />
                            )}
                          </div>
                        ) : (
                          <LockOpenIcon className="w-6 h-6" />
                        )}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                        {request.password.passwordIsSet ? (
                          <div className="flex justify-center">
                            <button
                              className={`text-indigo-600 p-2 rounded-xl  ${
                                requestsStatus[request.requestId] === RequestStatus.ACCEPTING
                                  ? "!cursor-not-allowed !bg-indigo-400 hover:bg-indigo-400"
                                  : "!cursor-pointer hover:bg-indigo-100"
                              }`}
                              disabled={requestsStatus[request.requestId] === RequestStatus.ACCEPTING}
                              onClick={() => {
                                if (requestsStatus[request.requestId] !== RequestStatus.ENTERING_PASSWORD) {
                                  setPasswordEntered("");
                                  requestsStatus[request.requestId] = RequestStatus.ENTERING_PASSWORD;
                                } else {
                                  accept(request.requestId);
                                }
                              }}
                            >
                              {!requestsStatus[request.requestId] && "Enter Password"}
                              {requestsStatus[request.requestId] === RequestStatus.WAITING && "Enter Password"}
                              {requestsStatus[request.requestId] === RequestStatus.ENTERING_PASSWORD && "Accept"}
                              {requestsStatus[request.requestId] === RequestStatus.ACCEPTING && (
                                <div className="flex text-white px-1">
                                  <LoadingIcon />
                                  Processing
                                </div>
                              )}
                            </button>
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            <button
                              className={`text-indigo-600 p-2 rounded-xl ${
                                requestsStatus[request.requestId] === RequestStatus.ACCEPTING
                                  ? "!cursor-not-allowed !bg-indigo-400 hover:bg-indigo-400"
                                  : "!cursor-pointer hover:bg-indigo-100"
                              }`}
                              disabled={requestsStatus[request.requestId] === RequestStatus.ACCEPTING}
                              onClick={() => accept(request.requestId)}
                            >
                              {!requestsStatus[request.requestId] && "Accept"}
                              {requestsStatus[request.requestId] === RequestStatus.WAITING && "Accept"}
                              {requestsStatus[request.requestId] === RequestStatus.ACCEPTING && (
                                <div className="flex text-white px-1">
                                  <LoadingIcon />
                                  Processing
                                </div>
                              )}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
