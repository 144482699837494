export default function Input({
  label,
  inlineLabel,
  type,
  placeholder,
  value,
  onChange,
  containerClassname,
  success,
  error,
}: {
  label?: string;
  inlineLabel?: React.ReactNode;
  type?: string;
  placeholder?: string;
  value: any;
  onChange: (newValue: any) => void;
  containerClassname?: string;
  success?: {
    isSuccessful?: boolean;
    message?: string;
  };
  error?: {
    hasError?: boolean;
    message?: string;
  };
}) {
  const changed = (newValue: any) => {
    onChange(newValue);
  };

  const getClassname = (staticClassnames: string, ...dynamic: string[]) => {
    return "" + staticClassnames + " " + dynamic.join(" ");
  };

  return (
    <div className={`min-w-full text-left ${containerClassname ? containerClassname : ""}`}>
      {label !== undefined && (
        <>
          {!!success?.message && success?.isSuccessful ? (
            <label className="text-green-600 w-full">{success.message}</label>
          ) : (
            <label>{label}</label>
          )}
          <br />
        </>
      )}
      <div className={`mt-1 relative`}>
        <input
          className={getClassname(
            " appearance-none border rounded w-full py-3 px-3 leading-tight focus-visible:outline-none focus-visible:border-slate-500 text-gray-700",
            success?.isSuccessful ? "!border-green-500" : "",
            error?.hasError ? "!border-red-500" : "",
          )}
          type={type ?? "text"}
          value={value}
          placeholder={placeholder ?? ""}
          onChange={e => changed(e.target.value)}
        />
        <div className={`absolute h-6 bottom-1/2 right-3`}>
          <div className={`relative -bottom-1/2`}>
            <div className={`absolut w-fit h-full text-slate-400`}>{inlineLabel}</div>
          </div>
        </div>
      </div>
      {!!error?.message && error?.hasError && <p className="text-red-600 w-full text-right mt-1">{error.message}</p>}
    </div>
  );
}
