import { useState } from "react";
import ArrowDown from "../SVGs/ArrowDown";

export default function Dropdown({
  label,
  placeholder,
  options,
  selectedChanged,
}: {
  label: string;
  placeholder?: string;
  options: string[];
  selectedChanged: (selected: string) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentlySelected, setCurrentlySelected] = useState(options[0]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const select = (option: string) => {
    toggle();
    setCurrentlySelected(option);
    selectedChanged(option);
  };

  return (
    <div className="flex flex-col items-start w-full">
      {label}
      <button
        className="flex justify-between items-center my-1 px-4 py-2.5 text-black text-sm bg-white hover:bg-slate-100 outline outline-1 outline-slate-300 w-full rounded-lg"
        type="button"
        onClick={toggle}
      >
        {currentlySelected ? currentlySelected : placeholder ?? "Select"}
        <div className="ml-2">
          <ArrowDown />
        </div>
      </button>
      <div
        className={`${isOpen ? "" : "hidden"} z-10 w-full text-left relative bg-white rounded divide-y divide-gray-100 shadow`}
      >
        <ul className="absolute w-full text-sm text-gray-700 border-1 border-gray-300 border overflow-hidden rounded-lg">
          {options.map(option => (
            <li onClick={() => select(option)} className="block py-2 px-4 bg-white hover:bg-gray-100 cursor-pointer">
              {option}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
