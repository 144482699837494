import "antd/dist/antd.css";
import { useBalance, useContractLoader, useContractReader, useOnBlock, usePoller } from "eth-hooks";
import { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import { Header } from "./components";
import IncomingSendRequests from "./components/SafeSend/IncomingSendRequests";
import OutgoingSendRequests from "./components/SafeSend/OutgoingSendRequests";
import { ALCHEMY_KEY, GOERLI_PROXY_CONTRACT_ADDRESS, NETWORKS } from "./constants";
import { RoutingParams } from "./constants/RoutingParams";
import externalContracts from "./contracts/external_contracts";
import { Web3ModalSetup } from "./helpers";
import { useStaticJsonRPC } from "./hooks";
import { Home } from "./views";
import { contractAbi } from "./views/contract-abi";
import { useSigner } from "wagmi";

const { ethers } = require("ethers");
/// 📡 What chain are your contracts deployed to?
const initialNetwork = NETWORKS.goerli; // <------- select your target frontend network (localhost, rinkeby, xdai, mainnet)

const DEBUG = false;
const USE_BURNER_WALLET = false; // toggle burner wallet feature

const web3Modal = Web3ModalSetup();

const providers = [
  "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
  `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  "https://rpc.scaffoldeth.io:48544",
];

function App(props) {
  // specify all the chains your app is available on. Eg: ['localhost', 'mainnet', ...otherNetworks ]
  // reference './constants.js' for other networks
  const networkOptions = [initialNetwork.name, NETWORKS.mainnet.name];

  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);

  const targetNetwork = NETWORKS[selectedNetwork];

  // 🔭 block explorer URL
  const blockExplorer = targetNetwork.blockExplorer;

  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : targetNetwork.rpcUrl,
  ]);
  const mainnetProvider = useStaticJsonRPC(providers);

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const { data: signer } = useSigner();

  useEffect(() => {
    async function getAddress() {
      if (signer) {
        const newAddress = await signer.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [signer]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId = signer && signer.provider && signer.provider._network && signer.provider._network.chainId;

  // For more hooks, check out 🔗eth-hooks at: https://www.npmjs.com/package/eth-hooks

  // 🏗 scaffold-eth is full of handy hooks like this one to get your balance:
  const yourLocalBalance = useBalance(localProvider, address);

  // Just plug in different 🛰 providers to get your balance on different chains:
  const yourMainnetBalance = useBalance(mainnetProvider, address);

  // const contractConfig = useContractConfig();

  const contractConfig = { deployedContracts: {}, externalContracts: externalContracts || {} };

  // Load in your local 📝 contract and read a value from it:
  const readContracts = useContractLoader(localProvider, contractConfig);

  // If you want to make 🔐 write transactions to your contracts, use the userSigner:
  const writeContracts = useContractLoader(signer, contractConfig, localChainId);

  // EXTERNAL CONTRACT EXAMPLE:
  //
  // If you want to bring in the mainnet DAI contract it would look like:
  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig);

  // If you want to call a function on a new block
  useOnBlock(mainnetProvider, () => {
    console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  });

  // Then read your DAI balance like:
  const myMainnetDAIBalance = useContractReader(mainnetContracts, "DAI", "balanceOf", [
    "0x34aA3F359A9D614239015126635CE7732c18fDF3",
  ]);

  /*
  const addressFromENS = useResolveName(mainnetProvider, "austingriffith.eth");
  console.log("🏷 Resolved austingriffith.eth as:",addressFromENS)
  */

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (
      DEBUG &&
      mainnetProvider &&
      address &&
      selectedChainId &&
      yourLocalBalance &&
      yourMainnetBalance &&
      readContracts &&
      writeContracts &&
      mainnetContracts
    ) {
      console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
      console.log("🌎 mainnetProvider", mainnetProvider);
      console.log("🏠 localChainId", localChainId);
      console.log("👩‍💼 selected address:", address);
      console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
      console.log("💵 yourLocalBalance", yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : "...");
      console.log("💵 yourMainnetBalance", yourMainnetBalance ? ethers.utils.formatEther(yourMainnetBalance) : "...");
      console.log("📝 readContracts", readContracts);
      console.log("🌍 DAI contract on mainnet:", mainnetContracts);
      console.log("💵 yourMainnetDAIBalance", myMainnetDAIBalance);
      console.log("🔐 writeContracts", writeContracts);
    }
  }, [
    mainnetProvider,
    address,
    selectedChainId,
    yourLocalBalance,
    yourMainnetBalance,
    readContracts,
    writeContracts,
    mainnetContracts,
    localChainId,
    myMainnetDAIBalance,
  ]);

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const [fromAddress, setFromAddress] = useState("");

  usePoller(async () => {
    const newFromAddress = await signer?.getAddress();
    const userConnected = !!newFromAddress;
    setFromAddress(userConnected ? newFromAddress : "");
  }, 1000);

  const contract = new ethers.Contract(GOERLI_PROXY_CONTRACT_ADDRESS, contractAbi, signer);

  return (
    <div className="App">
      <Header address={address}></Header>

      <Switch>
        <Route exact path="/">
          <Redirect to={`/${RoutingParams.SEND}`}></Redirect>
        </Route>
        <Route exact path={`/${RoutingParams.SEND}`}>
          <Home userSigner={signer} />
        </Route>
        <Route exact path={`/${RoutingParams.INCOMING}`}>
          {<IncomingSendRequests contract={contract} fromAddress={fromAddress} />}
        </Route>
        <Route exact path={`/${RoutingParams.OUTGOING}`}>
          {<OutgoingSendRequests contract={contract} />}
        </Route>

        <Route exact path={`/${RoutingParams.ABOUT}`}>
          <div className="h-full w-full xl:grid grid-cols-3 lg:block text-black  pb-52">
            <div className="flex flex-col items-center">
              <div className="mb-8 mt-20 text-2xl">How it works</div>

              <div style={{ width: 450, textAlign: "left" }}>
                <h2>Create a Send Request</h2>
                <ol>
                  <li>
                    1. Create a Send Request
                    <ul className="ml-6">
                      <li>- Recipient</li>
                      <li>- Amount</li>
                      <li>- Password</li>
                    </ul>
                  </li>
                  <li>2. Tell your recipient that you created the Send Request</li>
                  <li>3. Done</li>
                </ol>
                The Send Request is now created and waiting to be accepted.
                <h2 style={{ marginTop: 50 }}>Accept a Send Request as Recipient</h2>
                <ol>
                  <li>1. Go to alpha.send-eth.com and look at “Send Requests sent from someone else to me” </li>
                  <li>2. Pick a Send Request, enter the password if needed, and click “Accept”</li>
                  <li>3. Done</li>
                </ol>
              </div>
            </div>
            <div className="w-full flex flex-col items-center">
              <div className="flex flex-col items-center">
                <div className="mb-8 mt-20 text-2xl">Why?</div>
                <img style={{ width: 500 }} src="/twitter-screenshot.jpg" alt="twitter-screenshot"></img>
                <div className="flex justify-center mt-16">
                  <div style={{ width: 450, textAlign: "left" }}>
                    Remember the times you feared that you might be sending your money to the wrong address? Did you copy that
                    address correctly? <br />
                    Remember when you feared that you might never see that money again after hitting “send”? <br />
                    <br />
                    <br />
                    <h4>Fear no more! </h4>
                    Introducing: SafeSend. With SafeSend, you will never have this problem again. <br />
                    Instead of sending the money directly to the maybe correct address - you send it via a smart contract. <br />
                    You simply create a Send Request. The recipient then simply accepts the Send Request. <br />
                    To ensure it’s the correct recipient - you can also password protect your Send Requests! <br />
                    <br />
                    What happens if it is was the wrong recipient? <br />
                    😢 Before SafeSend: <br />
                    …<br />
                    …<br />
                    That’s right. <br />
                    Nothing. <br />
                    Money gone :( <br />
                    <br />
                    😊 With SafeSend, you can simply retract your Send Request and get all your money back. Then you can create a
                    new one with the correct details. <br />
                    It’s that easy never having to fear losing your money again? Yes. <br />
                    Simply SafeSend - without the worry. <br />
                    The best part? Everything is handled by a smart contract that can’t pull on any shady strings.
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-8 mt-20 text-2xl">FAQ</div>
              <div style={{ display: "flex", placeContent: "center" }}>
                <div style={{ marginTop: 20, width: 550, textAlign: "left" }}>
                  <p>
                    <strong>Question:</strong> I sent my Send Request to the wrong recipient - can they accept it without knowing
                    the password?
                  </p>
                  <p>
                    Answer: No. Even by reverse-engineering the creation of the Send Request it is not possible to accept the Send
                    Request without knowing the password. <br />
                    This is due to the advanced Password Encryption Process (explained below). <br />
                  </p>
                  <br />
                  <p>
                    <strong>Question:</strong> Can I re-use my password?
                  </p>
                  <p>
                    Answer: Yes. Even by reverse-engineering a previously created and accepted Send Request it is not possible for
                    a malicious party to accept the next Send Request that has the same password. <br />
                  </p>
                  <br />
                  <p>
                    <strong>Question:</strong> Is my Send Request vulnerable if a different account already used the same
                    password?
                  </p>
                  <p>
                    Answer: No. It is still safe. Even by reverse-engineering a previously created and accepted Send Request by a
                    different account that used the same password it is not possible to accept other Send Requests with the same
                    password from different accounts.
                  </p>
                  <br />
                  <p>
                    <strong>Question:</strong> I messed up the password and the recipient can’t accept it now. Can I get my money
                    back?
                  </p>
                  <p>
                    Answer: Of course! As the Sender, you can always retract the Send Request - even if you don’t know the
                    password. We know you were the one sending it ;) <br />
                    You can then simply recreate a new Send Request with a new password.
                  </p>
                  <br />
                  <p>
                    <strong>Question:</strong> Is my password that I enter stored somewhere?
                  </p>
                  <p>
                    Answer: No. The password you type in passes through an encryption process, that is engineered to ensure
                    security. It passes through a number of complex computations that can not be reversed.
                  </p>
                  <br />
                  <p>
                    <strong>Question:</strong> When I create the Send Request, this is publicly visible on the Blockchain. Can
                    someone find out what password I used?
                  </p>
                  <p>Answer: No. SafeSend uses an encryption process that ensures this is not possible. </p>
                  <br />
                  <p>
                    <strong>Question:</strong> Can someone else than the Recipient accept the Send Request?
                  </p>
                  <p>Answer: No. Only the recipient you specify can accept the Send Request with the correct password.</p>
                </div>
              </div>

              <div className="my-16 text-2xl">Password Encryption Flow</div>
              <div style={{ display: "flex", placeContent: "center", paddingBottom: 200 }}>
                <div style={{ width: 650, textAlign: "left" }}>
                  <h3>Creating the Send Request</h3>
                  <ol>
                    <li>The sender types in a Password. e.g. “@aR(lQ”</li>
                    <li>
                      The application adds the current date timestamp to this text e.g. “1662481984143” ⇒ “@aR(lQ1662481984143”
                    </li>
                    <li>
                      The application adds your public address to the text e.g. “0x42a3d6e125aad539ac15ed04e1478eb0a4dc1489” ⇒
                      “@aR(lQ16624819841430x42a3d6e125aad539ac15ed04e1478eb0a4dc1489”
                    </li>
                    <li>
                      This combination will then be passed through the keccak256 hash function. ⇒
                      “56e040553506d31a92723bc1a4b94c21fd1f4d982e3897c4de03839beac6ca6a”
                    </li>
                    <li>
                      This result will then again be passed into the keccak256 hash function ⇒
                      “f561b3155c731611c80ccc147b576ce08fc74f53bc6bce03f5091cac37241636”
                    </li>
                    <li>
                      This final result will be the password that the smart contract will use to verify the password from the
                      recipient (Additionally to this password, the current date timestamp from 2. will be saved in the smart
                      contract - the recipient needs this for the verification process)
                    </li>
                    <li>Done</li>
                  </ol>
                  <br />
                  <br />
                  <h3>Accepting the Send Request</h3>
                  <ol>
                    <li>The Recipient types in the Password “@aR(lQ” for a selected Send Request</li>
                    <li>
                      The application retrieves two pieces of information
                      <br />
                      <ul>
                        <li>the timestamp that was saved with the Request ("1662481984143")</li>
                        <li>the address of the sender ("0x42a3d6e125aad539ac15ed04e1478eb0a4dc1489")</li>
                      </ul>
                    </li>
                    <li>
                      The application adds the entered password and the two pieces of information together ⇒
                      “@aR(lQ16624819841430x42a3d6e125aad539ac15ed04e1478eb0a4dc1489”
                    </li>
                    <li>
                      This combination will then be passed through the keccak256 hash function. ⇒
                      “56e040553506d31a92723bc1a4b94c21fd1f4d982e3897c4de03839beac6ca6a”
                    </li>
                    <li>This is the result that will be passed to the smart contract with the accept request. </li>
                    <li>The smart contract receives the accept request</li>
                    <li>
                      The contract passes the value into the keccak256 hash algorithm ⇒
                      “f561b3155c731611c80ccc147b576ce08fc74f53bc6bce03f5091cac37241636”
                    </li>
                    <li>
                      The contract compares the result from this calculation with the password that was saved with the Send
                      Request when it was created by the Sender. If it matches, the recipient has proven that he knows the initial
                      password from the sender.
                    </li>
                    <li>Done</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default App;
