import { useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";

export default function ThemeSwitcher() {
  const { currentTheme } = useThemeSwitcher();

  useEffect(() => {
    window.localStorage.setItem("theme", "dark");
  }, [currentTheme]);

  return null;
}
