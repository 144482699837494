import { ArrowDownOnSquareIcon, ExclamationCircleIcon, ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ThemeSwitch } from ".";
import { RoutingParams } from "../constants/RoutingParams";
import Button from "./Dumb/Button";
import CustomSafeSendMe from "./SafeSend/Request/CustomSafeSendMe";
import { ConnectButton } from "@rainbow-me/rainbowkit";

export default function Header({ address }) {
  const location = useLocation();

  const [safeSendMeIsOpen, setSafeSendMeIsOpen] = useState(false);
  const [showWarningBanner, setShowWarningBanner] = useState(true);

  return (
    <>
      <div className="lg:grid lg:grid-cols-3 lg:justify-start px-12 py-2 flex flex-col justify-center">
        <div className="h-full w-full flex justify-center lg:items-start items-center flex-col">
          <a href={"/"} target="_blank" rel="noopener noreferrer">
            <div className="text-black text-xl">SafeSend</div>
          </a>
          <div className="relative w-full">
            {showWarningBanner && (
              <div className="absolute top-3 left-0 w-full">
                <div className="mt-2 w-fit bg-red-500 flex justify-center items-center p-2 rounded-md">
                  <ExclamationTriangleIcon className="h-5 w-5 text-white mr-1" />
                  <div className="text-white">This is an Alpha running ONLY on the Ethereum "Goerli" Testnet</div>
                  <XMarkIcon className="h-4 w-4 text-white ml-2 cursor-pointer" onClick={() => setShowWarningBanner(false)} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="h-full w-full flex justify-center items-center">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
              <div className="w-full flex justify-center py-8">
                <div className="flex bg-white text-white rounded-3xl shadow-lg p-1">
                  <div
                    className={`group font-semibold rounded-3xl py-1 px-5 mr-2 ${
                      location.pathname === `/${RoutingParams.SEND}` ? "bg-violet-500" : "hover:bg-violet-400"
                    }`}
                  >
                    <Link
                      className={`${
                        location.pathname === `/${RoutingParams.SEND}` ? "text-white" : "text-gray-400"
                      } ${"group-hover:text-white"} -m-2 p-2`}
                      to="/"
                    >
                      Send
                    </Link>
                  </div>
                  <div
                    className={`group font-semibold rounded-3xl py-1 px-5 mr-2 ${
                      location.pathname === `/${RoutingParams.INCOMING}` ? "bg-violet-500" : "hover:bg-violet-400"
                    }`}
                  >
                    <Link
                      className={`${
                        location.pathname === `/${RoutingParams.INCOMING}` ? "text-white" : "text-gray-400"
                      } ${"group-hover:text-white"} -m-2 p-2`}
                      to={`/${RoutingParams.INCOMING}`}
                    >
                      Incoming
                    </Link>
                  </div>
                  <div
                    className={`group font-semibold rounded-3xl py-1 px-5 mr-2 ${
                      location.pathname === `/${RoutingParams.OUTGOING}` ? "bg-violet-500" : "hover:bg-violet-400"
                    }`}
                  >
                    <Link
                      className={`${
                        location.pathname === `/${RoutingParams.OUTGOING}` ? "text-white" : "text-gray-400"
                      } ${"group-hover:text-white"} -m-2 p-2`}
                      to={`/${RoutingParams.OUTGOING}`}
                    >
                      Outgoing
                    </Link>
                  </div>
                  <div
                    className={`${
                      location.pathname === `/${RoutingParams.ABOUT}` ? "bg-violet-500" : "hover:bg-violet-400"
                    } group font-semibold rounded-3xl py-1 px-5`}
                  >
                    <Link
                      className={`${
                        location.pathname === `/${RoutingParams.ABOUT}` ? "text-white" : "text-gray-400"
                      } ${"group-hover:text-white"} -m-2 p-2`}
                      to={`/${RoutingParams.ABOUT}`}
                    >
                      About
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full w-full flex items-end flex-col">
          <div className="h-full flex justify-end items-center">
            <ConnectButton />
            <div className="relative">
              {address && (
                <div className="absolute top-10 right-0">
                  <div className="flex items-center justify-center">
                    <Button
                      type="outlined"
                      label="Request"
                      icon={<ArrowDownOnSquareIcon className="h-5 w-5" />}
                      onClick={() => setSafeSendMeIsOpen(!safeSendMeIsOpen)}
                    />
                  </div>
                  <div className="relative">
                    {safeSendMeIsOpen && (
                      <div className="absolute z-10 h-fit w-72 right-0 mt-3 bg-white rounded-3xl">
                        <CustomSafeSendMe address={address} />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <ThemeSwitch />
          </div>
          {!address && (
            <div className="text-white bg-red-500 py-1 px-2 rounded-sm flex justify-center items-center">
              <ExclamationCircleIcon className="h-5 w-5 mr-2" /> Please connect to MetaMask
            </div>
          )}
        </div>
      </div>
    </>
  );
}
