import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Button from "./Button";

export default function CopyButton({
  disabled,
  onClick,
  label,
  className,
}: {
  disabled?: boolean;
  label: string;
  onClick: () => void;
  className?: string;
}) {
  const [hasCopied, setHasCopied] = useState(false);
  const [hasCopiedAtLeastOnce, setHasCopiedAtLeastOnce] = useState(false);

  const copy = (e: any) => {
    setHasCopied(true);
    setHasCopiedAtLeastOnce(true);
    onClick();

    setTimeout(() => {
      setHasCopied(false);
    }, 1200);
  };

  return (
    <Button
      className={className}
      disabled={disabled}
      label={hasCopied ? "Copied!" : hasCopiedAtLeastOnce ? `Copy ${label} again` : `Copy ${label}`}
      onClick={copy}
      icon={<DocumentDuplicateIcon className="h-5 w-5" />}
    ></Button>
  );
}
