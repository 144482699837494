import { notification } from "antd";
import { Contract, ethers, providers, Signer } from "ethers";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Subject } from "rxjs";
import { QueryParams } from "../../../constants/QueryParams";
import useQueryParams from "../../../hooks/useQueryParams";
import Button from "../../Dumb/Button";
import PasswordForm from "../PasswordForm/PasswordForm";
import Amount from "./Amount";
import Recipient from "./Recipient";
import { usePoller } from "eth-hooks";
import { LockClosedIcon } from "@heroicons/react/20/solid";

export default function CreateSendRequest({
  userSigner,
  contract,
  provider,
}: {
  userSigner: Signer;
  contract: Contract;
  provider?: providers.Provider;
}) {
  const [fromAddress, setFromAddress] = useState<string>("");
  const [recipient, setRecipient] = useState<{ address: string; isValid: boolean }>({
    address: "",
    isValid: false,
  });
  const [recipientFromQueryParams, setRecipientFromQueryParams] = useState("");
  const [amountFromQueryParams, setAmountFromQueryParams] = useState("");
  const [currencyFromQueryParams, setCurrencyFromQueryParams] = useState("");

  const [amountInEther, setAmountInEther] = useState("");
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [salt, setSalt] = useState("" + Date.now());
  const [loading, setLoading] = useState(false);

  const queryParams = useQueryParams();
  const history = useHistory();

  usePoller(async () => {
    setFromAddress((await userSigner?.getAddress()) ?? "");
  }, 1000);

  useEffect(() => {
    setRecipientFromQueryParams(queryParams.get(QueryParams.RECIPIENT) ?? "");
    queryParams.delete(QueryParams.RECIPIENT);

    setAmountFromQueryParams(queryParams.get(QueryParams.AMOUNT) ?? "");
    queryParams.delete(QueryParams.AMOUNT);

    setCurrencyFromQueryParams(queryParams.get(QueryParams.CURRENCY) ?? "");
    queryParams.delete(QueryParams.CURRENCY);

    history.replace({
      search: queryParams.toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function hashedPasswordForSender(userPasswordInput: string) {
    if (!fromAddress) return "";

    const toHash = ethers.utils.toUtf8Bytes(userPasswordInput + "" + salt + "" + fromAddress);
    const firstHash = ethers.utils.keccak256(toHash);
    const doubleHash = ethers.utils.keccak256(firstHash);

    return doubleHash;
  }

  async function createSendRequest() {
    setLoading(true);

    try {
      const tx = await contract.createSendRequest(recipient.address, hashedPasswordForSender(password), salt, {
        value: ethers.utils.parseEther(amountInEther),
      });
      setSalt("" + Date.now());

      notification.success({
        message: "Confirmed",
        description: "Transaction is now processing",
      });
      await tx.wait();
      notification.success({
        message: "Success",
        description: `The recipient can now accept the Safe Send`,
      });

      setLoading(false);
      clearAll();
    } catch (e: any) {
      notification.error({
        message: "Create Error",
        description: e.toString(),
      });
      setLoading(false);
    }
  }

  const clearAll = () => {
    clearAll$.next();
  };

  const clearAll$ = new Subject<void>();

  return (
    <div className="flex justify-center text-black bg-white w-11/12 max-w-xl p-14 rounded-3xl shadow-2xl">
      <div className="w-full flex flex-col items-center">
        <Recipient
          provider={provider}
          recipientChanged={setRecipient}
          clearRecipient$={clearAll$}
          startWithRecipient={recipientFromQueryParams}
        />
        <div className="flex w-full mt-2">
          <Amount
            amountInEthChanged={setAmountInEther}
            clearAmount$={clearAll$}
            startWith={{ amount: amountFromQueryParams, currency: currencyFromQueryParams }}
          />
        </div>
        <PasswordForm
          containerClassname="mt-2"
          onChange={setPassword}
          setPasswordValid={setPasswordValid}
          clearPasswords$={clearAll$}
        />
        <Button
          className="mt-6"
          icon={<LockClosedIcon className="h-5 w-5 mr-1" />}
          label="Safe Send"
          loading={loading}
          type="primary"
          disabled={!recipient.isValid || !passwordValid || amountInEther === "" || isNaN(+amountInEther) || loading}
          onClick={createSendRequest}
        />
        <div className="mt-1 text-slate-500">Only the recipient can receive the transfer with the correct password</div>
      </div>
    </div>
  );
}
