import { useEffect, useMemo, useState } from "react";
import Input from "../../Dumb/Input";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { Subject } from "rxjs";

export default function PasswordForm({
  onChange,
  setPasswordValid,
  clearPasswords$,
  containerClassname = "",
}: {
  onChange: (password: string) => void;
  setPasswordValid: (password: boolean) => void;
  clearPasswords$?: Subject<void>;
  containerClassname?: string;
}) {
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");

  const [passwordsShown, setPasswordsShown] = useState(false);

  const passwordsMatch = useMemo(() => password === repeatedPassword, [password, repeatedPassword]);

  const handlePasswordChange = (changedPassword: any) => {
    setPassword(changedPassword);
    onChange(changedPassword);
    if (!changedPassword || !repeatedPassword) {
      setPasswordValid(false);
    } else {
      setPasswordValid(repeatedPassword === changedPassword);
    }
  };
  const handleRepeatedPasswordChange = (changedPassword: any) => {
    setRepeatedPassword(changedPassword);

    if (!changedPassword || !password) {
      setPasswordValid(false);
    } else {
      setPasswordValid(password === changedPassword);
    }
  };

  const showPasswordComponent = () => (
    <div className="cursor-pointer" onMouseDown={() => setPasswordsShown(true)} onMouseUp={() => setPasswordsShown(false)}>
      {!passwordsShown ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
    </div>
  );

  useEffect(() => {
    if (!clearPasswords$) return;

    clearPasswords$.subscribe(() => {
      handlePasswordChange("");
      handleRepeatedPasswordChange("");
    });
  }, [clearPasswords$, handlePasswordChange, handleRepeatedPasswordChange]);

  return (
    <div className={`${containerClassname} w-full`}>
      <Input
        containerClassname="mt-1"
        label="Secure Transfer with"
        type={passwordsShown ? "text" : "password"}
        value={password}
        placeholder="Enter your password"
        inlineLabel={showPasswordComponent()}
        onChange={handlePasswordChange}
        success={{ isSuccessful: repeatedPassword !== "" && passwordsMatch }}
        data-private
      />
      <Input
        containerClassname="mt-1"
        type={passwordsShown ? "text" : "password"}
        value={repeatedPassword}
        placeholder="Repeat password"
        inlineLabel={showPasswordComponent()}
        onChange={handleRepeatedPasswordChange}
        error={{ hasError: repeatedPassword !== "" && !passwordsMatch, message: "The entered passwords to not match" }}
        success={{ isSuccessful: repeatedPassword !== "" && passwordsMatch, message: "Match!" }}
        data-private
      />
    </div>
  );
}
