import { ethers, providers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Subject } from "rxjs";
import Input from "../../Dumb/Input";

export default function Recipient({
  provider,
  recipientChanged,
  clearRecipient$,
  startWithRecipient,
}: {
  provider?: providers.Provider;
  recipientChanged: (recipient: { address: string; isValid: boolean }) => void;
  clearRecipient$?: Subject<void>;
  startWithRecipient?: string;
}) {
  const [to, setTo] = useState("");
  const [recipientSuccessText, setRecipientSuccessText] = useState("");
  const recipientIsValid = useMemo(() => ethers.utils.isAddress(to), [to]);

  const setAndUpdateRecipient = useCallback(
    (recipient: string) => {
      setTo(recipient);
      recipientChanged({ address: recipient, isValid: ethers.utils.isAddress(recipient) });
    },
    [recipientChanged],
  );

  useEffect(() => {
    if (startWithRecipient) setAndUpdateRecipient(startWithRecipient);
  }, [setAndUpdateRecipient, startWithRecipient]);

  const updateRecipientSuccessText = useCallback(async () => {
    if (!provider || !ethers.utils.isAddress(to)) return;

    try {
      const balance = await provider.getBalance(to);
      const formatedBalance = ethers.utils.formatEther(balance.toString());

      setRecipientSuccessText("Valid address with a balance of " + formatedBalance + " ETH");
    } catch (e) {}
  }, [provider, to]);

  useEffect(() => {
    updateRecipientSuccessText();
  }, [updateRecipientSuccessText, to]);

  const pasteFromClipboard = () => {
    const paste = async () => {
      navigator.clipboard.readText().then(text => setAndUpdateRecipient(text));
    };
    paste();
  };

  useEffect(() => {
    if (!clearRecipient$) return;

    clearRecipient$.subscribe(() => {
      setAndUpdateRecipient("");
    });
  }, [clearRecipient$, setAndUpdateRecipient]);

  return (
    <Input
      label="Recipient"
      inlineLabel={
        <div className={`text-violet-600 font-medium cursor-pointer`} onClick={pasteFromClipboard}>
          Paste
        </div>
      }
      value={to}
      placeholder="0xd8dA6BF26964aF9D7eEd9e03E53415D37aA96045"
      onChange={setAndUpdateRecipient}
      success={{ isSuccessful: recipientIsValid, message: recipientSuccessText }}
      error={{ hasError: !!to && !recipientIsValid, message: "This is not a valid address" }}
    />
  );
}
